<template>
  <b-list-group-item>
    <div class="font-weight-bold text-primary">
      {{ label }}
    </div>
    <div class="font-weight-bold">
      {{ value }}
    </div>
  </b-list-group-item>
</template>

<script>
import { BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'ListItem',
  components: {
    BListGroupItem,
  },
  props: {
    label: {
      type: String,
      default: '-',
    },
    value: {
      type: String,
      default: '-',
    },
  },
}
</script>

<style scoped>

</style>
