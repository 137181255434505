<template>
  <div>
    <b-list-group>
      <list-item
        v-if="serviceData.related_person"
        label="İlgili Kişi"
        :value="serviceData.related_person"
      />
      <list-item
        v-if="serviceData.related_phone"
        label="İlgili Telefon"
        :value="serviceData.related_phone"
      />
      <list-item
        v-if="serviceData.invoiced_status"
        label="Ücretlendirme"
        :value="serviceData.invoiced_status"
      />
      <list-item
        v-if="serviceData.work_type"
        label="Çalışma Sınıfı"
        :value="serviceData.work_type"
      />
      <list-item
        v-if="serviceData.job"
        label="İş"
        :value="serviceData.job"
      />
      <list-item
        v-if="serviceData.finished"
        label="İş Durumu"
        :value="(serviceData.finished === '1')? 'Tamamlandı' : 'Devam Ediyor'"
      />
      <list-item
        v-if="serviceData.notes"
        label="Yapılan İşler / Kullanılan Malzemeler / Müşteri İle Mutabakat"
        :value="serviceData.notes"
      />
    </b-list-group>
  </div>
</template>
<script>
import {
  BListGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ListItem from '@/views/Machines/View/ListItem'

export default {
  name: 'ServiceCard',
  components: {
    BListGroup,
    ListItem,
  },
  props: {
    serviceData: Object,
  },
}
</script>

<style scoped>

</style>
